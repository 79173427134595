<!-- Login Page Content -->
<div class="content" *ngIf="page === 'login'">
    <div class="container-fluid">
        
        <div class="row m-1" style="margin-bottom: 25px !important;">
            <div class="col-md-12 ">
                
                <!-- Login Tab Content -->
                <div class="account-content">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-7 col-lg-6 login-left">
                            <img src="assets/img/login-banner.jpg" class="img-fluid" alt="DreamsCLG Login">	
                        </div>
                        <div class="col-md-12 col-lg-6 login-right">
                            <div class="login-header">
                                <h3>Login <span>Camrin Photography</span></h3>
                            </div>
                            <!-- <form action=""> -->
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control floating" [(ngModel)]="username" name="username" id="username" (ngModelChange)="onLoginEmailChange()">
                                    <span class="mandatory">{{loginEmailError}}</span>
                                </div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control floating" [(ngModel)]="password" name="password" id="password">
                                </div>
                                <span class="mandatory">{{loginError}}</span>

                                <div class="text-right">
                                    <a class="forgot-link" >Forgot Password ?</a>
                                </div>
                                <button class="btn btn-primary btn-block btn-lg login-btn" (click)="login()">Login</button>
                                <div class="login-or">
                                    <span class="or-line"></span>
                                    <span class="span-or">or</span>
                                </div>
                                <!-- <div class="row form-row social-login">
                                    <div class="col-6">
                                        <a href="#" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f mr-1"></i> Login</a>
                                    </div>
                                    <div class="col-6">
                                        <a href="#" class="btn btn-google btn-block"><i class="fab fa-google mr-1"></i> Login</a>
                                    </div>
                                </div> -->
                                <div class="text-center dont-have" style="cursor: pointer;">Don’t have an account? <a (click)="goToRegisterPage()">Register</a></div>
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
                <!-- /Login Tab Content -->
                    
            </div>
        </div>

    </div>
      <!-- Overlay and loader -->
  <div class="overlay" *ngIf="loading">
    <div class="loader-container">
      <div class="loader-div">
        <div class="loader">
          <div class="half"></div>
          <div class="half"></div>
        </div>
        <div class="loader-text">Loading .....</div>
      </div>
    </div>
  </div>

</div>		
<!-- /Login Page Content -->

<!-- Registration Page Content -->
<div class="content" *ngIf="page === 'register'">
    <div class="container-fluid">
        
        <div class="row m-1" style="margin-bottom: 25px !important;">
            <div class="col-md-12">
                    
                <!-- Register Content -->
                <div class="account-content">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-7 col-lg-6 login-left">
                            <img src="assets/img/login-banner.jpg" class="img-fluid" alt="Dreampa Register">	
                        </div>
                        <div class="col-md-12 col-lg-6 login-right">
                            <div class="login-header">
                                <h3>Register to Camrin Photography</h3>
                            </div>
                            
                            <!-- Register Form -->
                            <!-- <form action=""> -->
                                <div class="form-group">
                                    <label>First Name <span class="mandatory">*</span></label>
                                    <input type="text" class="form-control floating" [(ngModel)]="fname" name="fname" id="fname">
                                </div>
                                <div class="form-group">
                                    <label>Last Name </label>
                                    <input type="text" class="form-control floating" [(ngModel)]="lname" name="lname" id="lname">
                                </div>
                                <div class="form-group">
                                    <label>Email <span class="mandatory">*</span></label>
                                    <input type="text" class="form-control floating" [(ngModel)]="email" name="email" id="email" (ngModelChange)="onRegisterEmailChange()">
                                </div>
                                <span class="mandatory" style="position: relative;top: -15px;">{{registerEmailError}}</span>
                                <div class="form-group">
                                    <label>Phone <span class="mandatory">*</span></label>
                                    <input type="text" class="form-control floating" [(ngModel)]="phone" name="phone" id="phone" (ngModelChange)="onRegisterPhoneChange()">
                                </div>
                                <span class="mandatory" style="position: relative;top: -15px;">{{registerPhoneError}}</span>
                                <div class="form-group">
                                    <label>Password <span class="mandatory">*</span></label>
                                    <input type="password" class="form-control floating" [(ngModel)]="regPassword" name="regPassword" id="regPassword">
                                </div>
                                <span class="mandatory">{{registerError}}</span>
                                <div class="text-right">
                                    <a class="forgot-link" style="cursor: pointer;" (click)="goToLogin()">Already have an account?</a>
                                </div>
                                <button class="btn btn-primary btn-block btn-lg login-btn" (click)="register()">Signup</button>
                                <!-- <div class="login-or">
                                    <span class="or-line"></span>
                                    <span class="span-or">or</span>
                                </div>
                                <div class="row form-row social-login">
                                    <div class="col-6">
                                        <a href="#" class="btn btn-facebook btn-block"><i class="fab fa-facebook-f mr-1"></i> Login</a>
                                    </div>
                                    <div class="col-6">
                                        <a href="#" class="btn btn-google btn-block"><i class="fab fa-google mr-1"></i> Login</a>
                                    </div>
                                </div> -->
                            <!-- </form> -->
                            <!-- /Register Form -->
                            
                        </div>
                    </div>
                </div>
                <!-- /Register Content -->
                    
            </div>
        </div>

    </div>
      <!-- Overlay and loader -->
      <div class="overlay" *ngIf="loading">
        <div class="loader-container">
          <div class="loader-div">
            <div class="loader">
              <div class="half"></div>
              <div class="half"></div>
            </div>
            <div class="loader-text">Loading .....</div>
          </div>
        </div>
      </div>
</div>		
<!-- /Registration Page Content -->