<!-- Home Banner -->
<section class="section home-banner row-middle">
    <div class="container">
        <!-- <div class="row" style="margin-top: 6%;">
            <div class="col-12">
                <div class="card enquiry-card">
                    <div class="card-body">
                        <h4 class="card-title">Mark the Calender and Get Best Packages</h4>
                        <div class="education-info">
                                    <div *ngFor="let enquiry of enquiryList; let i=index" class="row form-row">
                                        <div class="col-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label>Event</label>
                                                <select class="form-control select" (change)="onSelectEventChange($event, i)">
                                                    <option value="">Select</option>
                                                    <option value="Wedding">Wedding</option>
                                                    <option value="Engagement">Engagement</option>
                                                    <option value="Reception">Reception</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label>Event Type</label>
                                                <select class="form-control select" (change)="onSelectEventTypeChange($event, i)">
                                                    <option value="">Select</option>
                                                    <option value="Groom Side">Groom Side</option>
                                                    <option value="Bride Side">Bride Side</option>
                                                    <option value="Groom & Bride">Groom & Bride</option>
                                                </select>
                                            </div> 
                                        </div>
                                        <div class="col-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label>Caste/Religion</label>
                                                <select class="form-control select" (change)="onSelectCasteChange($event, i)">
                                                    <option value="">Select</option>
                                                    <option value="Islam/Muslim">Islam/Muslim</option>
                                                    <option value="Hindu/Ezhava">Hindu/Ezhava</option>
                                                    <option value="Hindu/Nair">Hindu/Nair</option>
                                                    <option value="Hindu/General">Hindu/General</option>
                                                    <option value="Christian/General">Christian/General</option>
                                                    <option value="Christian/Penthacost">Christian/Penthacost</option>
                                                </select>
                                            </div> 
                                        </div>
                                        <div class="col-12 col-md-2 col-lg-2">
                                            <div class="form-group">
                                                <label>Event Date</label>
                                                    <input class="enquiry-date" type="date" (change)="SendDataonChange($event, i)"/>
                                            </div> 
                                        </div>
                                        <div *ngIf="i!==0" class="col-12 col-md-2 col-lg-1" style="margin-top: 28px; padding-left: 20px;">
                                            <a (click)="deleteEnquiry(i)" class="btn btn-danger trash"><i class="far fa-trash-alt"></i></a>
                                        </div>
                                    </div>
                        </div>
                        <div class="add-more" style="cursor: pointer;">
                            <a (click)="addMoreEnquiry()" class="add-education"><i class="fa fa-plus-circle"></i> Add More</a>
                            <div class="btn-item" style="float: right;margin-top: 0px;">
                                <a class="btn package-btn" routerLink='/pages/pricing-page'>Get Packages</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  -->
        <div class="row">
            <div class="col-lg-7 col-md-7">
                <div class="banner-content">
                    <p>Camrin Films</p> 
                    <h1>Capture your Best Moments.</h1>
                    <h1>Book your Camera.</h1>
                    <div class="btn-item">
                        <a class="btn get-btn" routerLink='/pages/login'>Get Started</a>
                        <a class="btn courses-btn" routerLink='/pages/pricing-page'>All Packages</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- /Home Banner -->

<!-- Our Colleges -->
<section class="section college">
<div class="container">
    <div class="loader-div" *ngIf="loading === true">
    <div class="loader">
        <div class="half"></div>
        <div class="half"></div>
    </div>
    <div style="color: black;">Loading .....</div>
    </div>
        <div class="row" style="margin-top: -2%;" *ngIf="loading === false">
            <div class="col-12">
                <div class="card enquiry-card">
                    <div class="card-body">
                        <div class="row form-row mobile-view">
                            <div class="col-6 col-md-4 col-lg-4">
                                <h4 class="card-title">Mark the Calender and Get Best Packages <span class="ml-2">|</span></h4>                               
                            </div>
                            <div class="col-2 col-md-1 col-lg-1">
                                <label>Event Type<span class="mandatory">*</span></label>
                            </div>
                            <div class="col-6 col-md-2 col-lg-2" style="left: -15px;bottom: 5px;">
                                <div class="form-group">                                    
                                    <select class="form-control select" [value]='eventType' (change)="onSelectEventTypeChange($event)">
                                        <option value="">Select</option>
                                        <option value="1">Single Side</option>
                                        <option value="2">Groom & Bride</option>
                                    </select>
                                </div> 
                            </div>
                            <div class="col-2 col-md-1 col-lg-1">
                                <label>Caste/Religion<span class="mandatory">*</span></label>
                            </div>
                            <div class="col-6 col-md-2 col-lg-2" style="left: 10px;bottom: 5px;">
                                <div class="form-group">
                                    <select class="form-control select" [value]="caste" (change)="onSelectCasteChange($event)">
                                        <option value="">Select</option>
                                        <option value="Islam/Muslim">Islam/Muslim</option>
                                        <option value="Hindu/Ezhava">Hindu/Ezhava</option>
                                        <option value="Hindu/Nair">Hindu/Nair</option>
                                        <option value="Hindu/General">Hindu/General</option>
                                        <option value="Christian/General">Christian/General</option>
                                        <option value="Christian/Penthacost">Christian/Penthacost</option>
                                    </select>
                                </div> 
                            </div>
                        </div>
                        <div class="container web-view">
                            <div class="row">
                              <div class="col-12 mb-3">
                                <h4 class="card-title">Mark the Calendar and Get Best Packages</h4>
                              </div>
                            </div>
                            
                            <div class="row mb-3">
                              <div class="col-6 col-md-4 mb-2">
                                <label for="eventType">Event Type<span class="text-danger">*</span></label>
                                <select class="form-control" id="eventType" [value]='eventType' (change)="onSelectEventTypeChange($event)">
                                  <option value="">Select</option>
                                  <option value="1">Single Side</option>
                                  <option value="2">Groom & Bride</option>
                                </select>
                              </div>
                              
                              <div class="col-6 col-md-4">
                                <label for="caste">Caste/Religion<span class="text-danger">*</span></label>
                                <select class="form-control" id="caste" [value]="caste" (change)="onSelectCasteChange($event)">
                                  <option value="">Select</option>
                                  <option value="Islam/Muslim">Islam/Muslim</option>
                                  <option value="Hindu/Ezhava">Hindu/Ezhava</option>
                                  <option value="Hindu/Nair">Hindu/Nair</option>
                                  <option value="Hindu/General">Hindu/General</option>
                                  <option value="Christian/General">Christian/General</option>
                                  <option value="Christian/Penthacost">Christian/Penthacost</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        
                        <div class="education-info">
                                    <div *ngFor="let enquiry of enquiryList; let i=index" class="row form-row">
                                        
                                        <div class="col-5 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label>Event<span class="mandatory">*</span></label>
                                                <select class="form-control select" (change)="onSelectEventChange($event, i)">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let event of eventList" [value]="event.eventId">{{event.displayName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label>Event Date<span class="mandatory">*</span></label>
                                                    <input class="enquiry-date" type="date" (change)="SendDataonChange($event, i)"/>
                                            </div> 
                                        </div>
                                        <div *ngIf="i!==0" class="col-1 col-md-2 col-lg-1" style="margin-top: 28px; padding-left: 5px;">
                                            <a (click)="deleteEnquiry(i)" class="btn btn-danger trash"><i class="far fa-trash-alt"></i></a>
                                        </div>
                                    </div>
                        </div>
                        <div class="add-more" style="cursor: pointer;">
                            <a (click)="addMoreEnquiry()" class="add-education"><i class="fa fa-plus-circle"></i> Add More</a>
                            <div [ngClass]="{ 'disabled': isDisabled , 'btn-item': !isDisabled}" style="float: right;margin-top: 0px;">
                                <a class="btn package-btn" (click)="openLoginModal()">Get Packages</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="row">
        <div class="col-12 col-md-7 mx-auto">
            <div class="section-header text-center">
                <h5>Recent Works</h5>
                <h2 class="header-title">DISCOVER OUR COUPLES</h2>
            </div>
        </div>
        
        <div class="col-md-12">
            <!-- Slider -->
            <ngx-slick-carousel class="carousel college-slider" #slickModal="slick-carousel" [config]="couplesSliderConfig">
                <div ngxSlickItem *ngFor="let slide of coupleslides">
                    <!-- Slider Item -->
                    <div class="services-item">
                        <div class="img-part">
                            <img src="{{slide.img}}" class="img-fluid" alt="">
                        </div>
                        <div class="content-part">
                        <div class="content-text">
                            <h3>{{slide.college}}</h3>
                            <h4 class="title">{{slide.course}}</h4>
                        </div>
                        </div>
                    </div>
                    <!-- /Slider Item -->
                </div>
            </ngx-slick-carousel>
            <!-- /Slider -->
        </div>
    </div>
  
    

    <div class="row">
        <div class="col-12">
            <div class="clg see-all text-center"> 
                <a routerLink='/instructors/college' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
            </div>
        </div>
    </div>
    <!--- End Colleges  -->
    
    <!--- Latest Courses  -->
    <div class="clg-course">
    <div class="row">
        <div class="col-12 col-md-7 mx-auto">
            <div class="section-header text-center">
                <h5>Latest Services</h5>
                <h2 class="header-title">WE PROVIDE BEST SERVICES</h2>
            </div>
        </div>
    </div>
        
    <div class="row">
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/couples/wedding-diamond.jpeg" alt="" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">4.2</div>
                            <!-- <div class="right">$40.00</div> -->
                        </div>
                    <div class="course-user">
                        <img src="assets/img/couples/diamond.png" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <!-- <h5>Imperial College London</h5> -->
                    <h2><a routerLink='/instructors/course-details'>Wedding</a></h2>
                    <!-- <p>Technology / Space / Electrical</p>							 -->
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Package</p>
                            <h5>Diamond</h5>
                        </div>
                        <div class="slot">
                            <p>Completed</p>
                            <h5>69</h5>
                        </div>
                        <div class="slot">
                            <p>Avg Amount</p>
                            <h5>96,000/- </h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->				
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/couples/reception-diamond.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">4.2</div>
                            <!-- <div class="right">$40.00</div> -->
                        </div>
                    <div class="course-user">
                        <img src="assets/img/couples/diamond.png" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <!-- <h5>Imperial College London</h5> -->
                    <h2><a routerLink='/instructors/course-details'>Engagement</a></h2>
                    <!-- <p>Technology / Space / Computer</p>							 -->
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Package</p>
                            <h5>Diamond</h5>
                        </div>
                        <div class="slot">
                            <p>Completed/p>
                            <h5>54</h5>
                        </div>
                        <div class="slot">
                            <p>Avg Amount</p>
                            <h5>68,000/- </h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/couples/wedding-gold.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">4.2</div>
                            <!-- <div class="right">$40.00</div> -->
                        </div>
                    <div class="course-user">
                        <img src="assets/img/couples/gold.png" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <!-- <h5>Imperial College London</h5> -->
                    <h2><a routerLink='/instructors/course-details'>Wedding</a></h2>
                    <!-- <p>Technology / Space / Construction</p>							 -->
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Package</p>
                            <h5>Gold</h5>
                        </div>
                        <div class="slot">
                            <p>Completed</p>
                            <h5>78</h5>
                        </div>
                        <div class="slot">
                            <p>Avg Amount</p>
                            <h5>65,000/-</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/couples/reception-silver.jfif" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">4.2</div>
                            <!-- <div class="right">$40.00</div> -->
                        </div>
                    <div class="course-user">
                        <img src="assets/img/couples/silver.png" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <!-- <h5>Imperial College London</h5> -->
                    <h2><a routerLink='/instructors/course-details'>Reception</a></h2>
                    <!-- <p>Technology / Space / Aerospace</p>							 -->
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Package</p>
                            <h5>Silver</h5>
                        </div>
                        <div class="slot">
                            <p>Completed</p>
                            <h5>132</h5>
                        </div>
                        <div class="slot">
                            <p>Avg Amount</p>
                            <h5>55,000/-</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/courses/course-05.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">3.2</div>
                            <div class="right">$40.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Imperial College London</h5>
                    <h2><a routerLink='/instructors/course-details'>Architectural Design</a></h2>
                    <p>Technology / Space / Architecture</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>200</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>100</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Months</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div> -->
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/courses/course-06.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">3.2</div>
                            <div class="right">$40.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Imperial College London</h5>
                    <h2><a routerLink='/instructors/course-details'>Bachelor of Fine Arts </a></h2>
                    <p>Technology / Space / Arts</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>200</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>100</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Months</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div> -->
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/courses/course-07.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">3.2</div>
                            <div class="right">$40.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Imperial College London</h5>
                    <h2><a routerLink='/instructors/course-details'>Business Management</a></h2>
                    <p>Technology / College / Business</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>200</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>100</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Months</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div> -->
        <!--- /Course-Item  -->
        
        <!--- Course-Item  -->
        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="course-section">
                <div class="course-top">
                    <div class="course-img">
                        <a routerLink='/instructors/course-details'><img src="assets/img/courses/course-08.jpg" alt="image" class="img-fluid"></a>
                    </div>
                    <div class="course-text d-flex justify-content-between align-items-center">
                        <div class="left">3.2</div>
                            <div class="right">$40.00</div>
                        </div>
                    <div class="course-user">
                        <img src="assets/img/instructors/instructor-thumb-01.jpg" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="course-content">
                    <h5>Imperial College London</h5>
                    <h2><a routerLink='/instructors/course-details'>Electrical Engineering</a></h2>
                    <p>Technology / Space / Electrical</p>							
                    <div class="d-flex align-items-center justify-content-between course-slots">
                        <div class="slot">
                            <p>Total Seats</p>
                            <h5>200</h5>
                        </div>
                        <div class="slot">
                            <p>Available</p>
                            <h5>100</h5>
                        </div>
                        <div class="slot">
                            <p>Duration</p>
                            <h5>6 Months</h5>
                        </div>
                    </div>
                    <div class="text-center">
                        <a routerLink='/instructors/course-details' class="btn course-btn">Book Now</a>
                    </div>
                </div>
            </div>
        </div> -->
        <!--- /Course-Item  -->
    </div>
    
    <div class="row">
        <div class="col-12">
            <div class="see-all  text-center"> 
                <a routerLink='/instructors/courses' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
            </div>
        </div>
    </div>
    
    </div>
</div>
</section>
<!-- END courses -->

<!-- Students Courses -->
<section class="section student">
<div class="container">
        <div class="row">
        <div class="col-md-10 mx-auto"> 
            <h5>We Are Best</h5>
            <h3>5500+ SATISFIED AND HAPPY CLIENTS</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eiusmod tempor incididunt ut laboreet dolore magna aliqua. Ut enim ad minim veniam, quised nostrud exercitation. Duis aute irure dolor in reprehen derit in voluptate </p>
            <div class="button"><a routerLink='/instructors/courses' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a></div>
        </div>
        </div>
    </div>
</section>
<!-- End Students Courses -->	


<!-- Review -->
<section class="section review">
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="section-header text-center">
                <h5>OUR REVIEWS</h5>
                <h2 class="header-title">CHECK OUR CLIENTS REVIEWS</h2>
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col-md-12">
            <!-- Slider -->
            <ngx-slick-carousel class="carousel review-slider" #slickModal="slick-carousel" [config]="reviewSliderConfig">
                <div ngxSlickItem *ngFor="let slide of rslides">
                    <!-- Review Widget -->
                    <div class="review-blog">
                        <div class="review-top d-flex align-items-center">
                            <div class="review-img">
                                <a href="javascript:void(0);"><img class="img-fluid" src="{{slide.img}}" alt="Post Image"></a>
                            </div>
                            <div class="review-info">
                                <h3>{{slide.name}}</h3>
                                <h5>{{slide.subject}}</h5>								
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star"></i>
                                    <span class="average-rating">3.2</span>
                                </div>
                            </div>
                        </div>
                        <div class="review-content">
                            <p>{{slide.desc}}</p>
                        </div>
                    </div>
                    <!-- / Review Widget -->
                </div>
            </ngx-slick-carousel>
            <!-- /Slider -->
        </div>					
    </div>
</div>
</section>
<!-- / Review -->

<!-- Register -->
<section class="section register">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-5 col-md-7 mx-auto text-center">
                <h5>Register Now</h5>
                <h2 class="header-title">Get 5+ Packages Details on Register</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eiusmod tempor incididunt ut laboreet dolore magna</p>
            </div>
            <div class="col-12 col-lg-12 mx-auto text-center">
                    <div class="reg-count d-flex justify-content-center">
                        <div class="count">
                            <span>45</span>
                            <p>Days</p>
                        </div>
                        <div class="count">
                            <span>12</span>
                            <p>Hrs</p>
                        </div>
                        <div class="count">
                            <span>36</span>
                            <p>Min</p>
                        </div>
                        <div class="count">
                            <span>36</span>
                            <p>Min</p>
                        </div>
                    </div>
                    <a routerLink='/pages/register' class="btn all-btn">View all <i class="fas fa-caret-right right-arrow"></i></a>
            </div>
        </div>
    </div>
    </section>
    <!-- End Register -->
     <section>
        <div style="min-height: 50px;"></div>
     </section>

